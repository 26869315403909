html, body { height: 100% }

.w-5  { width:  5%!important }
.w-10 { width: 10%!important }
.w-15 { width: 15%!important }
.w-20 { width: 20%!important }
.w-25 { width: 25%!important }
.w-30 { width: 30%!important }
.w-35 { width: 35%!important }
.w-40 { width: 40%!important }
.w-45 { width: 45%!important }
.w-50 { width: 50%!important }
.w-55 { width: 55%!important }
.w-60 { width: 60%!important }
.w-65 { width: 65%!important }
.w-70 { width: 70%!important }
.w-75 { width: 75%!important }
.w-80 { width: 80%!important }
.w-85 { width: 85%!important }
.w-90 { width: 90%!important }
.w-95 { width: 95%!important }

.text-extra-muted { color: #c7ced3 !important; }

table.table.table-borderless td,
table.table.table-borderless th {
    border: 0 !important;
}

.table-xs > thead > tr > th,
.table-xs > tbody > tr > th,
.table-xs > tfoot > tr > th,
.table-xs > thead > tr > td,
.table-xs > tbody > tr > td,
.table-xs > tfoot > tr > td {
  padding: 2px 4px;
  font-size: .9rem;
}

.list-group-xs > a,
.list-group-xs > div,
.list-group-xs > li {
    padding: .375rem 0.625rem
}

img.pdf {
    width: 18px;
    cursor: pointer;
    margin-top: -5px;
}

p.loading {
    text-align: center;
    margin-top: 200px;
    font-size: 40px;
    font-weight: bold;
    opacity: 0.3;
}

.section-header {
    background: #f7f7f9;
}

/*
body.modal-open {
    overflow: hidden;
}

.modal {
    overflow-y: auto;
}
*/

.modal-lg {
    max-width: 50% !important;
}

button:disabled, select:disabled {
    cursor: not-allowed;
}

p.version {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: right;
    margin: 0;
    padding: 0 5px 3px 0;
    opacity: 0.6;
    font-size: 12px;
}

body, body.modal-open {
    overflow: hidden;
}

div.tab-list {
    height: 10%;
}
div.tab-components {
    height: 90%;
}
i.video {
    opacity: 0.7;
    margin-left: 0.5em;
}
i.video {
    cursor: pointer;
}
i.video:hover {
    color: red;
}